<template>
  <div>
    <data-view :columnsFormat="columnsFormat" :askData="op_name" @refreshDo="chartRefresh">
      <template v-slot:default="dv_props">
        <el-tab-pane>
          <span slot="label" class="pane_label">
            <Icon type="ios-pie" />占比图
          </span>
          <div class="echarts-wrapper">
            <div class="single-chart-wrapper">
              <degree-level
                :choosedOptions="dv_props.choosedOptions"
                :drawAgain="updateCount"
                :esize="echartSize"
              ></degree-level>
            </div>
            <div class="single-chart-wrapper">
              <correct-stat
                :choosedOptions="dv_props.choosedOptions"
                :drawAgain="updateCount"
                :esize="echartSize"
              ></correct-stat>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane>
          <span slot="label" class="pane_label">
            <Icon type="md-podium" />统计图
          </span>
          <div class="single-chart-wrapper">
            <major-system
              :choosedOptions="dv_props.choosedOptions"
              :drawAgain="updateCount"
              :esize="echartSize"
            ></major-system>
          </div>
          <div class="single-chart-wrapper">
            <risk-sort
              :choosedOptions="dv_props.choosedOptions"
              :drawAgain="updateCount"
              :esize="echartSize"
            ></risk-sort>
          </div>
          <div class="single-chart-wrapper">
            <correct-demand
              :choosedOptions="dv_props.choosedOptions"
              :drawAgain="updateCount"
              :esize="echartSize"
            ></correct-demand>
          </div>
        </el-tab-pane>
        <el-tab-pane>
          <span slot="label" class="pane_label">
            <Icon type="ios-pie" />分布图
          </span>
          <div class="echarts-wrapper">
            <div class="single-chart-wrapper">
              <risk-spread
                :choosedOptions="dv_props.choosedOptions"
                :drawAgain="updateCount"
                :esize="echartSize"
              ></risk-spread>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane>
          <span slot="label" class="pane_label">
            <Icon type="md-clipboard" />分类说明
          </span>
          <div class="ins-wrapper">
            <risk-instruction></risk-instruction>
          </div>
        </el-tab-pane>
      </template>
    </data-view>
    <div class="fix-link" @click="goNav">风险点导航>></div>
  </div>
</template>
<script>
import dataView from "@/components/Common/DataView.vue";
import riskListExpand from "./RiskListExpand.vue";
import riskInstruction from "./RiskInstruction.vue";
import degreeLevel from "./Charts/DegreeLevel.vue";
import correctStat from "./Charts/CorrectStat.vue";
import majorSystem from "./Charts/MajorSystem.vue";
import riskSort from "./Charts/RiskSort.vue";
import riskSpread from "./Charts/RiskSpread.vue";
import correctDemand from "./Charts/CorrectDemand.vue";

export default {
  name: "riskList",
  props: [],
  components: {
    dataView,
    degreeLevel,
    correctStat,
    majorSystem,
    riskSort,
    correctDemand,
    riskInstruction,
    riskSpread
  },
  data() {
    return {
      op_name: this.$options.name,
      imgUrl: {
        分类说明: process.env.BASE_URL + "img/ins.jpg"
      },
      updateCount: 1,
      echartSize: { height: "700px", width: "1000px" },
      columnsFormat: [
        {
          type: "expand",
          width: 30,
          render: (h, params) => {
            return h(riskListExpand, {
              props: {
                row: params.row
              }
            });
          }
        },
        {
          title: "线路名称",
          key: "line_name",
          width: 90,
          align: "center",
          render: (h, p) => {
            let url = "/baseInfo/line_c/" + p.row.line_id;
            return h(
              "a",
              {
                attrs: {
                  href: url,
                  target: "_blank"
                }
              },
              p.row.line_name
            );
          }
        },
        {
          title: "评价时间",
          key: "evaluate_year",
          width: 105,
          sortable: true,
          align: "center"
        },
        {
          title: "风险点",
          key: "risk_content",
          align: "center",
          minWidth: 120,
          render: (h, p) => {
            let url = "/conclusion/risk_c/" + p.row.id.toString();
            return h(
              "a",
              {
                attrs: {
                  href: url,
                  target: "_blank"
                }
              },
              p.row.risk_content
            );
          }
        },
        {
          title: "风险类别",
          key: "sort",
          width: 110,
          align: "center",
          render: (h, p) => {
            return h(
              "Poptip",
              {
                props: {
                  trigger: "hover",
                  placement: "right",
                  wordWrap: true,
                  width: "360",
                  content:
                    "A（设计标准差异）、B（设备老化伤损严重可靠性差）、C（管理问题）、D（专业接口）、E（其他）。",
                  title: "类别说明"
                }
              },
              [h("span", {}, p.row.sort.replace(new RegExp("|", "g"), ""))]
            );
          },
          filters: [
            {
              label: "A",
              value: "A"
            },
            {
              label: "B",
              value: "B"
            },
            {
              label: "C",
              value: "C"
            },
            {
              label: "D",
              value: "D"
            },
            {
              label: "E",
              value: "E"
            }
          ],
          filterMethod(value, row) {
            return row.sort.indexOf(value) > -1;
          }
        },
        // {
        //   title: "分值",
        //   key: "score",
        //   sortable: true,
        //   width: 100,
        //   align: "center",
        //   render: (h, p) => {
        //     return h(
        //       "Poptip",
        //       {
        //         props: {
        //           trigger: "hover",
        //           placement: "right",
        //           wordWrap: true,
        //           width: "270",
        //           content:
        //             "该分值由专家评定，分数越大代表风险越严重；分值与严重度之间的具体关系为：0~25（较小的），25~50（一般的），50~75（较大的），75~100（严重的）。",
        //           title: "分值说明"
        //         }
        //       },
        //       [h("span", {}, p.row.score)]
        //     );
        //   },
        //   filters: [
        //     {
        //       label: ">60",
        //       value: 1
        //     },
        //     {
        //       label: "≤60",
        //       value: 2
        //     }
        //   ],
        //   filterMultiple: false,
        //   filterMethod(value, row) {
        //     if (value === 1) {
        //       return row.score > 60;
        //     } else if (value === 2) {
        //       return row.score < 60;
        //     }
        //   }
        // },
        {
          title: "技术专业",
          key: "major_system",
          width: 120,
          align: "center",
          filters: [
            {
              label: "供电",
              value: "供电"
            },
            {
              label: "土建",
              value: "土建"
            },
            {
              label: "信号",
              value: "信号"
            },
            {
              label: "车辆",
              value: "车辆"
            },
            {
              label: "通信",
              value: "通信"
            },
            {
              label: "安全管理",
              value: "安全管理"
            },
            {
              label: "机电",
              value: "机电"
            },
            {
              label: "运营组织",
              value: "运营组织"
            },
            // {
            //   label: "消防",
            //   value: "消防"
            // }
          ],
          filterMethod(value, row) {
            return row.major_system.indexOf(value) > -1;
          }
        },
        /** {
                        title: '相关类/专业接口',
                        key: 'involve_interface',
                        width: 120,
                        align: "center",
                        filters: [
                            {
                                label: '供电',
                                value: '供电'
                            },
                            {
                                label: '土建',
                                value: '土建'
                            },
                            {
                                label: '信号',
                                value: '信号'
                            },
                            {
                                label: '车辆',
                                value: '车辆'
                            },
                            {
                                label: '通信',
                                value: '通信'
                            },
                            {
                                label: '安全',
                                value: '安全'
                            },
                            {
                                label: '机电',
                                value: '机电'
                            },
                            {
                                label: '运营',
                                value: '运营'
                            },
                            {
                                label: '消防',
                                value: '消防'
                            },
                        ],
                        filterMethod(value, row) {
                            return row.interface.indexOf(value) > -1;
                        }
                    },**/
        {
          title: "严重度等级",
          key: "degree_level",
          width: 120,
          align: "center",
          filters: [
            {
              label: "较小的",
              value: "较小的"
            },
            {
              label: "一般的",
              value: "一般的"
            },
            {
              label: "较大的",
              value: "较大的"
            },
            {
              label: "重大的",
              value: "重大的"
            },
          ],
          filterMethod(value, row) {
            return row.degree_level.indexOf(value) > -1;
          }
        },

        /** {
                        title: '对策建议',
                        key: 'solution_proposal'
                    },**/
        {
          title: "整改要求",
          key: "correct_demand",
          width: 110,
          align: "center",
          filters: [
            {
              label: "计划整改",
              value: "计划整改"
            },
            {
              label: "急需整改",
              value: "急需整改"
            },
            {
              label: "专项研究",
              value: "专项研究"
            },
            {
              label: "加强维护管理",
              value: "加强维护管理"
            },
            {
              label: "加强人员培训",
              value: "加强人员培训"
            }
          ],
          filterMethod(value, row) {
            return row.correct_demand.indexOf(value) > -1;
          }
        },

        /** {
                        title: '归档编号',
                        width: 100,
                        align: "center",
                        key: 'archive_no'
                    },**/
        {
          title: "整改落实状态",
          width: 140,
          align: "center",
          key: "risk_correct_stat",
          filters: [
            {
              label: "整改中",
              value: "整改中"
            },
            {
              label: "已完成",
              value: "已完成"
            },
            {
              label: "常态化管理",
              value: "常态化管理"
            },
          ],
          filterMethod(value, row) {
            return row.risk_correct_stat.indexOf(value) > -1;
          }
        }
      ]
    };
  },
  methods: {
    goNav() {
      location.href = "/risk_nav";
    },
    chartRefresh() {
      this.updateCount++;
    },
    selectedListTextFormat(value) {
      let temp = "";
      for (let i of value) {
        temp += "(" + i.text + ")";
      }
      return temp;
    }
  }
};
</script>
<style scoped>
.fix-link {
  position: fixed;
  right: 1%;
  top: 30%;
  height: 36px;
  background-color: #555;
  color: #eee;
  border-radius: 2px;
  padding: 0px 20px;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  line-height: 36px;
  opacity: 0.8;
}
.fix-link:hover {
  background-color: #333;
  color: #fff;
}
.single-chart-wrapper{
  margin-top:10px;
}
</style>