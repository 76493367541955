<template>
<div class="outer-wrapper">
    <h1>一、风险分类</h1>
    <p>本次评估中，风险点的来源被分为5类，即：</p>
    <p>1、设计标准差异：由于城市轨道交通系统相关设施设备标准更新或需求提升，某些早期建成的设施设备不符合当前设计标准或新要求；</p>
    <p>2、设备老化损伤验证可靠性差：设备可靠性差或在长期使用中老化损伤，且缺少足够的维护，导致设备可靠性下降影响安全；</p>
    <p>3、管理问题：在运营组织和安全管理方面存在的疏忽和漏洞；</p>
    <p>4、专业接口：专业设备间的物理接口，或不同专业职能部门配合衔接不畅等，所产生的或无法及时处理的问题；</p>
    <p>5、其他：任何不属于前4类的风险点来源。</p>

    <p class="table-title">表1&nbsp;运营安全评估风险源分类表</p>
    <table>
        <tr>
            <td>风险源分类</td>
            <td>设计标准差异</td>
            <td>设备老化伤损严重、可靠性差</td>
            <td>管理问题</td>
            <td>专业接口</td>
            <td>其它</td>
        </tr>
        <tr>
            <td>编号</td>
            <td>A</td>
            <td>B</td>
            <td>C</td>
            <td>D</td>
            <td>E</td>
        </tr>
    </table>
    <h1>二、后果严重度等级化分与分值定义</h1>
    <p>本次评价中，风险等级由高到低分为4级：重大、较大、一般、较小。</p>
    <p>风险等级大小（D）由风险事件发生的可能性（L）、后果严重程度（C）两个指标决定。</p>
    <p>D=L*C。</p>
    <table>
        <tr>
            <td colspan="2">可能性级别</td>
            <td>极低</td>
            <td colspan="2">中等</td>
            <td colspan="3">高</td>
            <td colspan="3">极高</td>
            <td></td>
        </tr>
        <tr>
            <td colspan="2">后果严重程度</td>
            <td>1</td>
            <td>2</td>
            <td>3</td>
            <td>4</td>
            <td>5</td>
            <td>6</td>
            <td>7</td>
            <td>8</td>
            <td>9</td>
            <td>10</td>
        </tr>
        <tr>
            <td>不严重</td>
            <td>1</td>
            <td class="bg-green">较小</td>
            <td class="bg-green">较小</td>
            <td class="bg-green">较小</td>
            <td class="bg-green">较小</td>
            <td class="bg-green">较小</td>
            <td class="bg-blue">一般</td>
            <td class="bg-blue">一般</td>
            <td class="bg-blue">一般</td>
            <td class="bg-blue">一般</td>
            <td class="bg-blue">一般</td>
        </tr>
        <tr>
            <td>较严重</td>
            <td>2</td>
            <td class="bg-green">较小</td>
            <td class="bg-green">较小</td>
            <td class="bg-blue">一般</td>
            <td class="bg-blue">一般</td>
            <td class="bg-blue">一般</td>
            <td class="bg-blue">一般</td>
            <td class="bg-blue">一般</td>
            <td class="bg-blue">一般</td>
            <td class="bg-blue">一般</td>
            <td class="bg-blue">一般</td>
        </tr>
        <tr>
            <td>严重</td>
            <td>5</td>
            <td class="bg-green">较小</td>
            <td class="bg-blue">一般</td>
            <td class="bg-blue">一般</td>
            <td class="bg-blue">一般</td>
            <td class="bg-yellow">较大</td>
            <td class="bg-yellow">较大</td>
            <td class="bg-yellow">较大</td>
            <td class="bg-yellow">较大</td>
            <td class="bg-yellow">较大</td>
            <td class="bg-yellow">较大</td>
        </tr>
        <tr>
            <td>特别严重</td>
            <td>10</td>
            <td class="bg-yellow">较大</td>
            <td class="bg-yellow">较大</td>
            <td class="bg-yellow">较大</td>
            <td class="bg-red">重大</td>
            <td class="bg-red">重大</td>
            <td class="bg-red">重大</td>
            <td class="bg-red">重大</td>
            <td class="bg-red">重大</td>
            <td class="bg-red">重大</td>
            <td class="bg-red">重大</td>
        </tr>

    </table>
</div>
    
</template>
<style scoped>
.outer-wrapper{
    width:800px;
    margin:0 auto;
    padding-bottom: 100px;
}
h1{
    text-align: center;
    line-height:100px;
}

p{
    font-size:16px;
    text-indent: 32px;
    line-height: 32px;
}

p.table-title{
    margin:20px 0;
    text-align: center;
}

table{
    width:100%;
    border-collapse: collapse;
    font-size: 16px;
}

td{
    border:1px solid #666;
    text-align: center;
    line-height: 48px;
}

.bg-red{
    background-color: red;
    color:#fff;
}

.bg-blue{
    background-color: blue;
    color:#fff;
}

.bg-green{
    background-color: green;
    color:#fff;
}

.bg-yellow{
    background-color: yellow;
    color:#000;
}


</style>
<script>
export default {
    name:'riskInstruction',
    data() {
            return {
            };
    }
}
</script>